import React, { useState, useEffect } from "react"

import { useInterval } from "react-use"
import { FaDollarSign } from "react-icons/fa"
import { MdGroup, MdPerson, MdHourglassFull } from "react-icons/md"

import { Link } from "gatsby"
import styled from "@emotion/styled"
import { OutboundLink } from "gatsby-plugin-amplitude-analytics"

import { LargeHeroLayout } from "../components/layouts"
import Image from "../components/generic/image"
import SEO from "../components/seo"
import { Testimonial } from "../components/testimonial"
import { Infographic } from "../components/svg"
import { allTestimonials } from "../constants/testimonials"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60rem;
  margin: 0 auto;

  > div {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 920px) {
    > div {
      margin-bottom: 6rem;
    }

    > div:last-child {
      margin-bottom: 0;
    }
  }
`

const LevelUpRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div.copy {
    margin: 0 2rem;
  }

  div.image {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 2rem 0;
  }

  div.image:after {
    content: " ";
    background-color: #1e437a;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 90%;
    top: 0;
    bottom: 0;
  }

  div.image > div.overlay-text {
    position: absolute;
    top: 50%;
    left: -2rem;
    z-index: 2;
    transform: rotate(270deg);
    font-size: 1.25rem;
    span:first-of-type {
      color: #728bb6;
    }

    span:last-of-type {
      color: white;
    }
  }

  @media screen and (min-width: 920px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4rem;

    div.copy {
      max-width: 40%;
    }

    div.image {
      max-width: 50%;

      .gatsby-image-wrapper {
        min-height: 16rem;
      }
    }
  }
`

const SuperchargeRow = styled.div`
  width: 100%;

  div.copy {
    margin: 0 2rem;
  }

  @media screen and (min-width: 920px) {
    position: relative;
    div.copy {
      margin-right: 50%;
    }
  }
`

const InfographicContainer = styled.div`
  width: 100%;

  @media screen and (min-width: 920px) {
    order: -1;
    position: absolute;
    width: 50%;
    right: 0;
    top: 42.5%;
    transform: translateY(-50%);
    z-index: -1;

    h2 {
      text-align: center;
    }
  }
`

const PricingOptsRow = styled.div`
  width: 100%;

  h1 {
    margin: 2rem;
  }

  div.card {
    display: flex;
    flex-direction: column;
    background-color: #1d3661;
    padding: 2rem;
    margin-bottom: 2rem;

    h2 {
      color: #728bb6;
      font-size: 1.75rem;
      margin: 0;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.2rem;
      margin: 0;
      margin-bottom: 2rem;
    }

    ul {
      width: 100%;
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 0;
      margin-bottom: 2rem;

      li {
        flex: 1 1 33%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-right: 0.25rem;
        }
      }
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 3rem;
      border: none;
      border-radius: 0.5rem;
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
      text-decoration: none;
      background-color: #2b5192;
    }
  }

  @media screen and (min-width: 920px) {
    display: flex;
    flex-wrap: wrap;

    h1 {
      width: 100%;
      text-align: center;
    }

    div.card {
      width: calc(50% - 1rem);
      margin-bottom: 0;

      h2 {
        text-align: center;
      }

      p {
        text-align: center;
        padding-bottom: 1rem;
        margin-bottom: auto;
      }

      ul {
        margin-top: 2rem;
      }

      a {
        background-color: #24447a;
      }

      a:hover {
        background-color: #2b5192;
      }
    }

    div.card:first-of-type {
      margin-right: auto;
    }
  }
`

const OtherLessonsRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  div.copy {
    order: 1;
    margin: 1rem 2rem;

    h2 {
      font-size: 1.25rem;
      word-break: keep-all;
    }

    p {
      a {
        color: #95a8c9;
      }
    }
  }

  div:nth-child(2) {
    order: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    clip-path: polygon(0 87.5%, 0 0, 100% 0, 100% 100%);
  }

  div:nth-child(3) {
    order: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 12.5%);
  }

  @media screen and (min-width: 920px) {
    position: relative;
    flex-direction: row;
    align-items: center;
    width: 100%;

    div.copy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      order: 1;
      height: 18rem;
      width: 100%;
      padding: 1rem 2rem;
      background-color: #1d3661;
    }

    div:nth-child(2) {
      position: absolute;
      z-index: -1;
      order: 0;
      height: 16rem;
      width: 50rem;
      left: 5%;
      clip-path: none;
      opacity: 0.65;
    }

    div:nth-child(3) {
      position: absolute;
      z-index: -1;
      order: 2;
      height: 16rem;
      width: 50rem;
      right: 5%;
      clip-path: none;
      opacity: 0.65;
    }
  }
`

const TestimonialRow = styled.div<{ opacity?: number }>`
  position: relative;
  background-color: #35373a;

  &::before {
    display: none;
  }

  > div {
    transition: opacity linear 0.5s;
    opacity: ${(p) => p.opacity};
  }

  @media screen and (min-width: 920px) {
    &::before {
      content: " ";
      display: block;
      position: absolute;
      background-color: #35373a;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      height: 100%;
    }
  }
`

const PriceExtended = styled.div`
  display: flex;
  flex-direction: column;
`

const GST = styled.sup`
  font-size: 0.75rem;
  opacity: 0.5;
`

const IndexPage = () => {
  const [testimonialOpacity, setTestimonialOpacity] = useState(1)
  const [testimonialIndex, setTestimonialIndex] = useState(0)

  useInterval(() => {
    const nextIndex = (testimonialIndex + 1) % allTestimonials.length
    setTestimonialOpacity(0)

    setTimeout(() => {
      setTestimonialIndex(nextIndex)
    }, 500)
  }, 8000)

  useEffect(() => {
    setTestimonialOpacity(1)
  }, [testimonialIndex])

  return (
    <LargeHeroLayout>
      <SEO title="Six Strings Townsville | Guitar School" />
      <Content>
        <LevelUpRow>
          <div className="image">
            <Image src="/assets/images/DamienTeachingStudent.jpg" />
            <div className="overlay-text">
              <span>Learn</span>
              <span>guitar</span>
            </div>
          </div>
          <div className="copy">
            <h2 className="subtitle">Level up with</h2>
            <h1>Six Strings Townsville</h1>
            <p>
              We love all things music and guitar. Our goal is to share the joy
              of playing music by helping our students better understand their
              instrument and learn to appreciate music from new and exciting
              angles.
            </p>
          </div>
        </LevelUpRow>

        <SuperchargeRow>
          <InfographicContainer>
            <Infographic />
          </InfographicContainer>
          <div className="copy">
            <h1>Supercharge your brain</h1>
            <p>Did you know that learning music develops your brain?</p>
            <p>
              It's true - learning an instrument enhances brain connectivity and
              long-term memory.
            </p>
            <p>
              This positively influences other areas such as language and
              self-discipline.
            </p>
            <p>Oh... and it also makes you happy, which is nice.</p>
          </div>
        </SuperchargeRow>

        <PricingOptsRow>
          <h1>Secure a spot today</h1>
          <div className="card">
            <h2>Go solo</h2>
            <p>You, your guitar and a tutor.</p>
            <p>Skill up fast with focused one-on-one lessons.</p>
            <ul>
              <li>
                <MdHourglassFull />
                30 min
              </li>
              <li>
                <PriceExtended>
                  <div>
                    <FaDollarSign /> 55 ea
                  </div>
                  <GST>(incl. GST)</GST>
                </PriceExtended>
              </li>
              <li>
                <MdPerson />1
              </li>
            </ul>
            <OutboundLink href="/contact?enquiryType=solo">
              Book now
            </OutboundLink>
          </div>

          <div className="card">
            <h2>Go in a team</h2>
            <p>You, a few new friends and a tutor.</p>
            <p>
              Take advantage of the low price with group lessons, all still with
              the same great content.
            </p>
            <ul>
              <li>
                <MdHourglassFull />
                30 min
              </li>
              <li>
                <PriceExtended>
                  <div>
                    <FaDollarSign /> 29.20 pp
                  </div>
                  <GST>(incl. GST)</GST>
                </PriceExtended>
              </li>
              <li>
                <MdGroup />4
              </li>
            </ul>
            <Link to="/contact?enquiryType=group">Book now</Link>
          </div>
        </PricingOptsRow>

        <OtherLessonsRow>
          <div className="copy">
            <h1>Need a Guitar or Equipment?</h1>
            <p>
              Check out Artist Gutiars from Sydney, they have a great range with the best bang for buck!{" "}
              <OutboundLink
                href="https://alnk.to/2FBuPFz"
                target="_blank"
              >
                Click Here 
              </OutboundLink>
              {" "}to access some great deals.
            </p>
          </div>
          <Image
            src="src/assets/images/GuitarWall.png"
            objectPosition="bottom center"
          />
          <Image
            src="src/assets/images/ArtistLogo.png"
            objectPosition="bottom center"
          />
        </OtherLessonsRow>

        <TestimonialRow opacity={testimonialOpacity}>
          <Testimonial author={allTestimonials[testimonialIndex].author}>
            {allTestimonials[testimonialIndex].quote}
          </Testimonial>
        </TestimonialRow>
      </Content>
    </LargeHeroLayout>
  )
}

export default IndexPage
